<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <b-btn variant="info" @click="novoFiltro">
          <b-icon-plus-circle /> Filtro
        </b-btn>
      </div>
      <div class="col-12 col-md-4 text-right">
        <export-excel :data="pedidosFiltrados">
          <b-btn variant="secondary"> <b-icon-file-excel /> Exportar </b-btn>
        </export-excel>
      </div>
      <div class="col-12" v-if="filtros && filtros.length > 0">
        <hr />

        <b-card>
          <label>Adicionar Filtro</label>
          <div
            class="row"
            v-for="fil in filtros"
            :key="`filtro_num${filtros.indexOf(fil)}`"
          >
            <div class="col-4">
              <label>Campos</label>
              <b-select
                :options="campos"
                v-model="fil.campo"
                @change="preencheFilter(fil)"
              />
            </div>
            <div class="col-4">
              <label>Condição</label>
              <b-select
                :options="[
                  'Igual',
                  'Diferente',
                  'Seja um dos valores',
                  'Contém',
                  'Maior',
                  'Maior ou igual',
                  'Menor',
                  'Menor ou igual',
                ]"
                v-model="fil.tipo"
              />
            </div>
            <div class="col-4 d-flex">
              <div>
                <label
                  >Valor
                  <small
                    v-if="
                      typeof fil.tipo != 'undefined' &&
                      fil.tipo == 'Senha um dos valores'
                    "
                    >*separe os valores por vírgula</small
                  ></label
                >
                <!-- <b-input v-model="fil.valor" /> -->
                <v-select
                  v-model="fil.valor"
                  :options="fil.opcs"
                  :push-tags="true"
                  taggable
                  :multiple="fil.tipo == 'Seja um dos valores'"
                  style="min-width: 200px"
                />
              </div>
              <b-btn
                variant="danger"
                @click="excFiltro(fil)"
                size="sm"
                style="margin-top: 30px; margin-left: 10px; max-height: 30px"
                ><b-icon-x
              /></b-btn>
            </div>
          </div>
          <b-btn variant="success" class="mt-2" @click="novoFiltro">
            <b-icon-plus /> Adicionar
          </b-btn>
        </b-card>
        <b-btn block class="mt-4" variant="success" @click="filtrarPedidos"
          ><b-icon-filter-circle /> Aplicar Filtros</b-btn
        >
        <b-btn block class="mt-1" variant="warning" @click="limparFiltros"
          ><b-icon-x-circle-fill /> Limpar Filtros</b-btn
        >
      </div>
      <div class="col-12 mt-2">
        <b-card style="font-size: 11px">
          <label>Campos</label>
          <b-form-checkbox-group
            :options="fields.map((it) => ({ text: it.label, value: it.key }))"
            v-model="fieldsVisible"
          />
        </b-card>
      </div>
    </div>
    <hr />
    <div class="text-center p-5" v-if="loading">
      <b-spinner /><br />
      carregando...
    </div>
    <div
      class="row"
      v-if="pedidosFiltrados && pedidosFiltrados.length > 0 && !loading"
    >
      <div class="col-6">
        <b-card title="Qtd. Total" class="text-center">
          <h1>{{ pedidosFiltrados.length }}</h1>
        </b-card>
      </div>
      <div class="col-6">
        <b-card title="Valor Total" class="text-center">
          <h1>
            {{
              pedidosFiltrados.reduce((ret, vl) => {
                ret += vl.vl_total;
                return ret;
              }, 0) | currency
            }}
          </h1>
        </b-card>
      </div>
    </div>
    <div class="row mt-4" v-if="!loading">
      <div class="col-12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          style="margin: 0 auto"
          size="sm"
          class="mb-3 float-right"
        ></b-pagination>
        <b-table
          :items="pedidosFiltrados"
          :fields="fields"
          responsive
          stacked="sm"
          style="font-size: 10px; text-align: center"
          small
          ref="table"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY HH:mm:ss") }}
          </template>
          <template #cell(excluido)="row">
            {{ row.item.excluido == 1 ? "X" : "" }}
          </template>
          <template #cell(cliente)="row">
            {{ row.item.cliente.Nome }}
          </template>
          <template #cell(usuario_cadastro)="row">
            {{
              row.item.usuario_cadastro
                ? row.item.usuario_cadastro.nome || ""
                : ""
            }}
          </template>
          <template #cell(vl_subtotal)="row">
            {{ row.item.vl_subtotal || "" | currency }}
          </template>
          <template #cell(vl_desconto)="row">
            {{
              (row.item.vl_desconto && row.item.vl_desconto > 0
                ? row.item.vl_desconto
                : "") | currency
            }}
          </template>
          <template #cell(vl_acrescimo)="row">
            {{
              (row.item.vl_acrescimo && row.item.vl_acrescimo > 0
                ? row.item.vl_acrescimo
                : "") | currency
            }}
          </template>
          <template #cell(vl_entrega)="row">
            {{
              (row.item.vl_entrega && row.item.vl_entrega > 0
                ? row.item.vl_entrega
                : "") | currency
            }}
          </template>
          <template #cell(vl_total)="row">
            {{ row.item.vl_total || "" | currency }}
          </template>

          <template #cell(vl_dinheiro)="row">
            {{ row.item.vl_dinheiro || "" | currency }}
          </template>
          <template #cell(vl_cartao)="row">
            {{ row.item.vl_cartao || "" | currency }}
          </template>
          <template #cell(vl_troco)="row">
            {{ row.item.vl_troco || "" | currency }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Pedido from "../../libs/Pedido";
export default {
  components: {},
  props: {
    empresaSelecionada: Array,
    dateRange: Object,
  },
  mounted() {
    this.filtros = [
      {
        campo: "Cancelado",
        tipo: "Igual",
        valor: 0,
      },
      {
        campo: "Status",
        tipo: "Igual",
        valor: 4,
      },
    ];

    this.carregar();
    this.fieldsVisible = this.fields.map((it) => it.key);
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pedidos: [],
      pedidosFiltrados: [],
      loading: false,
      addNewFilter: false,
      filtros: [],
      filtro: [],
      fieldsVisible: [],
      fields: [
        { sortable: true, key: "cod_pedido", label: "Código" },
        { sortable: true, key: "tipo_entrega", label: "Tipo" },
        { sortable: true, key: "senha", label: "Senha" },
        { sortable: true, key: "mesa_controle_id", label: "Nº Conta" },
        { sortable: true, key: "empresa", label: "Empresa" },
        { sortable: true, key: "caixa", label: "Caixa" },
        { sortable: true, key: "data", label: "Dt/Hora" },
        { sortable: true, key: "moto", label: "Moto" },
        { sortable: true, key: "cliente", label: "Cliente" },
        { sortable: true, key: "status", label: "Status" },
        { sortable: true, key: "status", label: "Status" },
        { sortable: true, key: "usuario_cadastro", label: "Usuário" },
        { sortable: true, key: "sistema", label: "Sistema" },
        { sortable: true, key: "vl_subtotal", label: "Sub-total" },
        { sortable: true, key: "vl_desconto", label: "Desconto" },
        { sortable: true, key: "vl_acrescimo", label: "Acrescimo" },
        { sortable: true, key: "vl_entrega", label: "Entrega" },
        { sortable: true, key: "vl_total", label: "Total" },
        { sortable: true, key: "pagamento", label: "F. Pgto" },
        { sortable: true, key: "vl_dinheiro", label: "V. Dinheiro" },
        { sortable: true, key: "vl_troco", label: "V. Troco" },
        { sortable: true, key: "vl_cartao", label: "V. Cartão" },
        { sortable: true, key: "excluido", label: "Cancelado" },
        { sortable: true, key: "pedido_erro", label: "Problema" },
      ],
    };
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    fieldsVisible: {
      deep: true,
      handler() {
        this.fields.forEach((it) => {
          if (!this.fieldsVisible.some((x) => x == it.key)) {
            it.class = "d-none";
          } else {
            delete it.class;
          }
          this.$refs.table.refresh();
        });
      },
    },
    filtros: {
      deep: true,
      handler(n, o) {
        console.log(n, o);
        // this.filtros.forEach(it => {
        //   it.opcs = this.getValoresPossiveis(
        //                   this.fields.find((x) => x.label == it.campo)
        //                     ? this.fields.find((x) => x.label == it.campo).key
        //                     : ''
        //                 )
        // })
      },
    },
  },
  computed: {
    campos() {
      return this.fields.map((it) => it.label);
    },
  },
  methods: {
    async preencheFilter(fil) {
      fil.opcs = await this.getValoresPossiveis(
        this.fields.find((x) => x.label == fil.campo)
          ? this.fields.find((x) => x.label == fil.campo).key
          : ""
      );
    },
    excFiltro(fil) {
      this.filtros = this.filtros.filter((x) => x != fil);
      if (this.filtros.length <= 0) this.filtrarPedidos();
    },
    limparFiltros() {
      this.filtros = [];
      this.filtrarPedidos();
    },
    async filtrarPedidos() {
      this.loading = true;
      await this.$nextTick();
      this.$nextTick(() => {
        try {
          //  'Maior ou igual',
          //             'Menor',
          //             'Menor ou igual',
          //             'Diferente',
          let pedidos = Object.assign([], this.pedidos);

          if (this.filtros && this.filtros.length > 0) {
            const retValor = (value) => {
              if (
                typeof value != "undefined" &&
                value &&
                value.toString() != ""
              ) {
                if (typeof value == "number") {
                  value =
                    value.toString().indexOf(".") ||
                    value.toString().indexOf(",")
                      ? parseFloat(value)
                      : parseInt(value);
                } else if (typeof value == "object") {
                  value = value[Object.keys(value)[0]];
                }
              } else {
                value = "";
              }
              // console.log("ret val", value);
              return value;
            };
            for (let fil of this.filtros) {
              if (fil.campo != "" && fil.tipo != "" && fil.valor != "") {
                console.log("filtro aplicado", fil);
                if (fil.tipo == "Igual") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ).toString() == retValor(fil.valor).toString()
                  );
                } else if (fil.tipo == "Maior ou igual") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ) >= retValor(fil.valor)
                  );
                } else if (fil.tipo == "Maior") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ) > retValor(fil.valor.toString())
                  );
                } else if (fil.tipo == "Menor") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ) < retValor(fil.valor)
                  );
                } else if (fil.tipo == "Menor ou igual") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ) <= retValor(fil.valor)
                  );
                } else if (fil.tipo == "Diferente") {
                  pedidos = pedidos.filter(
                    (x) =>
                      retValor(
                        x[this.fields.find((c) => c.label == fil.campo).key]
                      ) != retValor(fil.valor)
                  );
                } else if (fil.tipo == "Seja um dos valores") {
                  let spl = fil.valor.toString().split(" ").join("").split(",");
                  pedidos = pedidos.filter((x) =>
                    spl.includes(
                      x[
                        this.fields.find((c) => c.label == fil.campo).key
                      ].toString()
                    )
                  );
                } else if (fil.tipo == "Contém") {
                  pedidos = pedidos.filter(
                    (x) =>
                      x[this.fields.find((c) => c.label == fil.campo).key]
                        .toString()
                        .toLowerCase()
                        .indexOf(fil.valor.toString().toLowerCase()) >= 0
                  );
                }
              }
            }
          }
          this.pedidosFiltrados = pedidos;
          this.totalRows = pedidos.length;
        } catch (err) {
          console.log("erro ao filtrar pedidos", err);
        }
        this.loading = false;
      });
    },
    novoFiltro() {
      this.filtros.push({
        campo: "",
        tipo: "",
        valor: "",
        opcs: [],
      });
    },
    async carregar() {
      if (this.loading) {
        while (this.loading) {
          this.$nextTick();
        }
        this.carregar();
      }
      this.loading = true;
      try {
        this.pedidos = (
          await Pedido.get({
            data: moment(this.dateRange.start).format("YYYY-MM-DD"),
            dataAte: moment(this.dateRange.end).format("YYYY-MM-DD"),
            empresas: this.empresaSelecionada.map((it) => it.cod_empresa),
          })
        ).data;
        this.totalRows = this.pedidos.length;
        console.log("concluido pedidos", this.pedidos);
        this.filtros.forEach((it) => {
          this.preencheFilter(it);
        });
        this.filtrarPedidos();
        console.log("concluido filtro");

        this.loading = false;
      } catch (err) {
        console.log("ERRO CAR PEDS", err);
      }
      this.loading = false;
    },
    async getValoresPossiveis(campo) {
      if (!campo || campo == "") return [];
      let lista = await this.pedidos.reduce((ret, vl) => {
        let vla =
          typeof vl[campo] == "object"
            ? vl[campo][Object.keys(vl[campo])[0]]
            : vl[campo];
        if (!ret.some((x) => x == vla)) {
          ret.push(vla);
        }
        return ret;
      }, []);
      // console.log("opcs", lista);
      return lista;
    },
  },
};
</script>

<style lang="scss" scoped></style>
